import {ListStore, SingleStore} from 'storwork-stores'

let currentId = false
let currentType = false
let currentStore = false
export default (id) => {

  if (id === currentId) return currentStore
  currentStore = new SingleStore({
    log: true,
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['name', 'first_name', 'last_name', 'full_name'],
      type: 'Contact',
      multi: false
    }],
    type: 'contact',
    id: id
  })

  return currentStore
}
