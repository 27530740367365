import {ListStore, SingleStore} from 'storwork-stores'

let currentId = false
let currentType = false
let currentStore = false
export default (id) => {

  if (id === currentId) return currentStore
  currentStore = new SingleStore({
    log: true,
    defaultObject: {
      notes: [],
      products: ['new_value']
    },
    entities: [
      {
        path: '',
        alwaysSave: true,
        fields: ['name', 'description', 'price', 'status', 'min', 'max', 'start', 'end', 'contact_id'],
        type: 'Order',
        multi: false
      },
      {
        path: 'information',
        fields: ['content', 'type'],
        type: 'Information',
        multi: true,
      },
      {
        path: 'products',
        specialRelation: 'products'
      }
    ],
    type: 'order',
    id: id
  })

  return currentStore
}
