import {JWT} from 'storwork-stores'
export const fileUpload = (file, type, object_id, object_type, callback = () => {}) => {
  const formData = new FormData();
  formData.append('object_id', object_id)
  formData.append('object_type', object_type)
  formData.append('type', type)
  formData.append('files[]', file);
  fetch((process.env.REACT_APP_UPLOAD_URL || 'https://b2b.storwork.pl/api/upload'), {
    method: "POST",
    headers: {
      Authorization: "Bearer " + JWT.get()
    },
    body: formData
  }).then(response => {
    return response.json()
  }).then(response => {
    callback(response);
  });
}

export const getUrlFromInformation = (information, filename) => {
  const InformationContent = JSON.parse(information.content);
  if (InformationContent.length > 0) {
    fetch((process.env.REACT_APP_DOWNLOAD_URL || 'https://b2b.storwork.pl/api/download') + '/' + InformationContent[0].download, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + JWT.get()
      }
    })
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.blob();
      })
      .then(blob => {
        var url = URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch(error => {
        // console.log(error);
        // setTimeout(() => {
        //   getUrlFromInformation(information, callback)
        // }, 1500);
      })

  }
}
