import {ListStore} from 'storwork-stores'

let currentParent = false
let currentStore = false
export default () => {
  // if (parentId === currentParent) return currentStore
  // currentParent = parentId
  currentStore = new ListStore({
    log: true,
    size: 15,
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['name', 'description'],
      type: 'Order',
      multi: false
    }],
    type: 'order',
    staticFilters: {
      order: 'created_at.timestamp desc'
    }
  })
  return currentStore
}
