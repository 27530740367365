import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {observer} from 'mobx-react'
import _ from 'lodash'
import OrderStore from "./stores/OrderStore";
import Create from "./Create";
import PropsInput from './Input'
import AutoComplete from './AutoComplete'
import AddProduct from './AddProduct'

export default props => <Order store={props.store} list={props.list} />

const Order = observer(props => {
  return <div>
    <Creator store={props.store} list={props.list}/>
  </div>
})


class Creator extends React.Component {
  state = {
    products: ['new_value']
  };

  render() {
    const {props} = this;

    const Input = PropsInput(props.store)

    // console.log(props.store);

    return <form onSubmit={e => {
        e.preventDefault()
        console.log('asdsadas')
        props.store.sync().then(setTimeout(() => {
          props.store.object = _.clone(props.store.defaultObject)
          props.list.loadRecords()
        }, 1000))
      }}>
      <div className='columns'>
        <div className='column'>
          <Input path='object.name' placeholder={'Nazwa zamówienia...'} className={'input'} /><br /><br />
          <Input path='object.start' placeholder={'Start...'} className={'input'} /><br /><br />
          <Input path='object.end' placeholder={'End...'} className={'input'} />
        </div>
        <div className='column'>
          <Input path='object.price' placeholder={'Wartość...'} className={'input'} /><br /><br />
          <Input path='object.min' placeholder={'Min...'} className={'input'} /><br /><br />
          <Input path='object.max' placeholder={'Max...'} className={'input'} />
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <AutoComplete indexType='contact' input={Input} store={props.store} path={'object.contact_id'} placeholder={'Szukaj kontaktu...'}/><br/>
        </div>
        <div className='column'>
          {this.state.products.map((product, key) => {
            return <div key={key}>
              <AddProduct indexType='product' index={key} store={props.store} />
            </div>
          })}
        </div>
      </div>
      <button className='button is-success' type="submit">Zapisz</button>
    </form>
  }
}
