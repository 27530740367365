import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {JWT} from 'storwork-stores'
import {observer} from 'mobx-react'
import OrderStore from "./stores/OrderStore";
import OrdersStore from "./stores/OrdersStore";
import ContactStore from "./stores/ContactStore";
import ProductStore from "./stores/ProductStore";
import Create from "./Create";
import PropsInput from './Input'
import AutoComplete from './AutoComplete'
import AddProduct from './AddProduct'
import Dropzone from 'react-dropzone'
import { fileUpload, getUrlFromInformation } from './services/files'

export default props => {
  let defaultStore = false;
  let listStore = false;
  if(props.match.params.type === 'contact') {
    defaultStore = ContactStore(props.match.params.id);
  } else if(props.match.params.type === 'product') {
    defaultStore = ProductStore(props.match.params.id);
  } else {
    defaultStore = OrderStore(props.match.params.id);
    listStore = OrdersStore()
  }

  return <Details store={defaultStore} list={listStore} type={props.match.params.type} />
}

const Details = observer(props => {
  if(!props.store) return null;

  const object = props.store.object;

  if(!object.id) return null;

  if(props.type === 'contact')
    return <Contact data={object}/>
  else if(props.type === 'order')
    return <Order data={object} store={props.store}/>
  else if(props.type === 'product')
    return <Product data={object}/>

})

const Contact = observer(props => {
  const contact = props.data;

  return <div>
    <h2 className='title is-2'>
      {contact.full_name}
    </h2>
    <aside class="menu">
      <ul class="menu-list">
        {contact.orders.map(order => {
          return <li>
            <Link to={'/order/' + order.id}>{order.name}</Link>
          </li>
        })}
      </ul>
    </aside>
  </div>
})

const Product = observer(props => {
  const product = props.data;

  return <div>
    <h2>
      {product.name}
    </h2>
  </div>
})

const Information = observer(props => {
  const content = JSON.parse(props.content)[0]
  return <div onClick={() => {
      getUrlFromInformation(props, content.filename)}}>
    {content.filename}
    <hr/>
  </div>
})

const InformationContainer = observer(props => {
  const {id, name, type, client} = props
  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      fileUpload(file, type, id, 'order')
    });
  }
  return <div>
    {client.information.filter(el => el.type === type).map(el => <Information {...el} />)}
    <Dropzone style={{
      width: '100%',
      height: '120px',
      lineHeight: '120px',
      textAlign: 'center',
      border: '1px dashed black',
      cursor: 'pointer'
    }} onDrop={onDrop}>
      Dodaj załącznik...
    </Dropzone>
  </div>
})


const Order = observer(class _Order extends React.Component {
  state = {
    products: [],
    note: ''
  }

  componentDidMount() {
    // this.setState({products: this.props.store.object.products.map(product => {
    //     return product.id
    //   })
    // });
    this.setState({
      index: this.props.store.object.products.length
    })
  }

  addNote = () => {
    this.props.store.object.information.push({
      history: [{
        user: JWT.remember().claim.user.full_name
      }],
      content: JSON.stringify({
        note: this.state.note
      }),
      type: 'note'
    })
    this.props.store.sync()
    this.setState({note: ''})
  }



  render() {
    const props = this.props;

    const order = props.data;

    const Input = PropsInput(props.store)

    return <div>
      <h5 className='title is-5'>
        {order.name}
        {order.contact && <span> dla <Link to={'/contact/' + order.contact.id}>{order.contact.full_name}</Link>
        </span>}
      </h5>
      <table className='table' style={{width: '100%'}}>
        <thead>
        <tr>
          <th>L.p.</th>
          <th>Nazwa</th>
          <th>Dostępność</th>
          <th>Ilość</th>
          <th>Cena</th>
        </tr>
        </thead>
        <tbody>
        {order.products.map((product, key) => {
          const availablePercent = (product.available && product.quantity ? (product.available / product.quantity) * 100 : '');
          return <tr key={key}>
            <td width="5%">{key + 1}</td>
            <td width="50%">
              {/*<Link to={'/details/product/' + product.id}>*/}
                {product.name}
              {/*</Link>*/}
            </td>
            <td width="5%">{product.available} {(product.available && product.quantity) ?
              <sup>{availablePercent}%</sup> : '-'}</td>
            <td width="5%">{product.quantity}</td>
            <td width="5%">{product.price}</td>
          </tr>
        })}
        </tbody>
      </table>
      <div className='columns'>
        <div className='column'>
          <InformationContainer type='file' client={props.store.object} id={props.store.object.id} />
        </div>
        <div className='column'>
        {order.information && order.information.filter(el => el.type === 'note').map(note => {
          const text = JSON.parse(note.content);
          return <div key={note.id}>
            <div>{text.note}</div>
            <hr/>
          </div>
        })}
          <textarea value={this.state.note} onChange={(e) => this.setState({note: e.target.value})} placeholder='Dodaj notatkę...' className='textarea' /><br />
          <button onClick={this.addNote} className='button is-success'>Dodaj notatkę</button>
        </div>
      </div>
    </div>
  }
})
