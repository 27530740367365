import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash';
import Autosuggest from 'react-autosuggest'
import axios from 'services/axios'
import { debounce } from 'throttle-debounce'

// import './styles.css'

class AutoComplete extends React.Component {
  state = {
    value: '',
    id: '',
    suggestions: [],
    selected: {}
  }

  componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    )
  }

  valueSelected = (suggestion) => {
    this.setState({
      selected: suggestion
    });
  }

  renderSuggestion = suggestion => {
    return (
      <div className="result" onMouseOver={() => this.valueSelected(suggestion)}>
        <span>{suggestion.unified_name}</span>
      </div>
    )
  }

  onChange = (event, { newValue, sth }) => {
    this.setState({value: newValue})
    _.set(this.props.store, this.props.path, this.state.selected.id)
  }

  onSuggestionsFetchRequested = ({ value }) => {
    axios().post(process.env.REACT_APP_CD + '/elastic/storwork/_search', {
        _source: ['id', 'unified_name', 'indexType'],
        query: {
          bool: {
            must: [{
              multi_match: {
                query: value,
                fields: ['unified_name']
              }
            }],
            filter: {
              term: {
                indexType: this.props.indexType || 'contact'
              }
            }
          }
        },
        sort: ['_score', { 'created_at.timestamp': 'desc' }]
      })
      .then(res => {
        const results = res.data.hits.hits.map(h => h._source)
        this.setState({ suggestions: results })
      })
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] })
  }

  render() {
    const { id, value, suggestions, selected } = this.state

    const inputProps = {
      placeholder: this.props.placeholder || 'Szukaj...',
      value: value,
      id: id,
      onChange: this.onChange,
      path: this.props.path,
      selected: selected,
      className: 'input'
    }

    const Input = this.props.input;

    const renderInputComponent = inputProps => (
      <div>
        <input {...inputProps} className="input"/>
        <Input path={inputProps.path} value={selected.id} hidden />
      </div>
    );

    return (
      <div>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.unified_name}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
        />
      </div>
    )
  }
}

export default AutoComplete;