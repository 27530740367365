import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {observer} from 'mobx-react'
import OrdersStore from "./stores/OrdersStore";
import OrderStore from "./stores/OrderStore";
import Create from "./Create";
import {Link} from 'react-router-dom'

export default props => <Orders store={OrderStore()} list={OrdersStore()}/>

const Orders = observer(props => {
  return <div>
    <table width="100%" class="table is-hoverable">
      <tbody>
        <tr>
          <td>L.p.</td>
          <td>Zamówienie</td>
          <td>Data dodania</td>
        </tr>
        {props.list.records.map((record, key) => {
          return <tr key={key}>
            <td>
              {key+1}
            </td>
            <td>
              <Link to={'/order/' + record.id}>
                {record.name}
              </Link>
            </td>
            <td>
              {record.created_at.value}
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <Create store={props.store} list={props.list} />
  </div>
})
