import React from 'react'
import _ from 'lodash'
import {observer} from 'mobx-react'

const setJson = (json, path, value) => {
  const parsedJson = JSON.parse(json) || {}
  _.set(parsedJson, path, value)
  return JSON.stringify(parsedJson)
}

export default that => observer(props => {
  const {className = '', placeholder = ''} = props
  const { path, json, callback, options, hidden, textarea } = props
  let {value} = props;
  const pathValue = _.get(that, path)
  // console.log(path);
  // console.log(json);
  const jsonValue = json ? _.get(JSON.parse(pathValue), json) : false
  const onChange = e => {
    const value = e.target ? e.target.value : e
    const newValue = json ? setJson(pathValue, json, value) : value
    _.set(that, path, newValue)
    if (callback && options) callback()
  }

  value = json ? jsonValue : (pathValue ? pathValue : value)

  const insideProps = {onChange, value, className, placeholder, hidden, path, textarea}

  if (props.children) return React.cloneElement(React.Children.only(props.children), insideProps)
  return options ? <select native {...insideProps}>
    {options.map((option, key) => <option key={key} value={option.value}>{option.label || option.value}</option>)}
  </select> : (textarea ? <textarea onBlur={callback || (() => {})} {...insideProps} /> : <input onBlur={callback || (() => {})} {...insideProps} />)
})
