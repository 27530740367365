import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {StorworkInit} from 'storwork-stores'
import Grid from './Grid'
import List from './List'
import Navbar from './Navbar'
import Details from './Details'
import * as C from 'storwork-components'

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<Grid>
      <div area='navbar' size='50px' type='row' style={{gridArea: 'navbar'}}>
        <Navbar />
      </div>
      <div area='list' size='50%' type='column' style={{gridArea: 'list', padding: '0 10px'}}>
        <Route component={List} path='/' />
      </div>
      <div area='main' style={{gridArea: 'main', padding: '0 10px'}}>
        <Route component={Details} exact path='/:type/:id' />
      </div>
    </Grid>} />
  }
}

export default App;
