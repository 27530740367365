import React, { Component } from 'react'

import PropsInput from "./Input";
import AutoComplete from './AutoComplete'

export default (props) => {
  const Input = PropsInput(props.store)
  const index = props.index;

  return <div>
    <AutoComplete indexType={props.indexType} input={Input} store={props.store} path={'object.products.' + index + '.id'} placeholder={'Szukaj produktu...'}/>
  </div>
}