import React from 'react'
import _ from 'lodash'

let grid = false
const gridStart = () => {
  grid = {
    rows: ['auto'],
    cols: ['auto'],
    areas: [['main']]
  }
}

const addRow = (size, name, direction = 'top') => {
  if (direction === 'top') {
    grid.areas.unshift(grid.areas[0].map(el => name))
    grid.rows.unshift(size)
  } else {
    grid.areas.push(grid.areas[0].map(el => name))
    grid.rows.push(size)
  }
}

const addColumn = (size, name, direction = 'left') => {
  if (direction === 'left') {
    grid.areas = grid.areas.map(row => { row.unshift(name); return row })
    grid.cols.unshift(size)
  } else {
    grid.areas = grid.areas.map(row => { row.push(name); return row })
    grid.cols.push(size)
  }
}

const recurseChild = (child) => {
  if (!child || !child.props) return false
  if (child.props.area === undefined && child.props.children) {
    return recurseChild(child.props.children)
  }
  if (child.props.condition && !child.props.condition()) return false
  return child
}

export default class Grid extends React.Component {
  render () {
    const children = this.props.children
    gridStart()
    for (let i = 0; i < children.length; i++) {
      let child = recurseChild(children[children.length - 1 - i])
      if (!child) continue
      const {type, size, area, direction} = child.props
      if (type === 'column') {
        addColumn(size, area, direction)
      } else if (type === 'row') {
        addRow(size, area, direction)
      }
    }
    let style = {
      display: 'grid',
      gridTemplateColumns: grid.cols.join(' '),
      gridTemplateRows: grid.rows.join(' '),
      gridTemplateAreas: grid.areas.map(area => `"${area.join(' ')}"`).join(' '),
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
    _.merge(style, this.props.style)

    return <div {...this.props}
      style={style}
    >
      {this.props.children}
    </div>
  }
}
